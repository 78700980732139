<template>
    <div id="comments" class="ml-auto">    
        <h2>Komentarze</h2>
        <div 
			v-for="(comment, index) in comments" 
			:key="index" 
			class="my-2 px-3 d-flex aling-center justify-center" >
            <Avatar 
				size="40" 
				:name='comment.name' 
				noLink 
				class="align-center-element"
				/> 
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title class="mb-1 text-left">{{comment.name}}</v-list-item-title>
                    <v-card 
						max-width="700"
						flat  
						class="wordbreak pa-2 pl-0 text-left" 
						v-html="comment.content"
					>
					</v-card>
                    <v-list-item-subtitle  
						class="text-left caption"
					>  
						{{comment.date ? comment.date.split('T')[0] : null}} {{correctDate(comment.date)}} 
					</v-list-item-subtitle>
                </v-list-item-content>
                <v-btn
                    v-if="role === 'administrator' || role === 'moderator'"
                    small text @click='deleteComment(comment._id)'
                >
					X
				</v-btn>
            </v-list-item>
        </div>
        <v-flex 
			row 
			height="500" 
			max-width="1000"  
			class="align-start py-3 px-0 pl-5 "
		>
			<v-card 
				flat 
				class="mx-auto mb-4 d-flex"  
				max-width="1000"
			>
				<v-card-text max-width="1000">
					<VueEditor v-model="text" class="wordbreak" />
					<v-btn 
						:disabled="!isEmpty"
						class="mt-4 ml-auto mr-0" 
						@click="sendComment" 
						:loading="isLoaded"
					>
						Dodaj
					</v-btn>
				</v-card-text>
			</v-card>
        </v-flex> 
    </div>
</template>


<script>
import { VueEditor } from "vue2-editor";
import Avatar from "@/components/molecules/Avatar.vue";
import io from 'socket.io-client';
export default {
    data() {
        return {
			isLoaded: false,
            text: '',
            socket: io(this.url),
            comments: [],
        }
    },
    computed: {
		isEmpty(){
			return this.text.length > 0;
		},
        role(){
            return this.$store.getters.getUserRole;
		},
		userID(){
			return this.$store.getters.getUserID;
		},
		userMail(){
			return this.$store.getters.getUserMail;
		},
	
    },
    components: {
		VueEditor,
        Avatar
    },
    methods: {
		currentFullDate(){
			return new Date().toISOString()
		},
		correctDate(date){
			const newDate = new Date(date);
			return `${newDate.getHours()}:${newDate.getMinutes() < 9 ?  '0' + newDate.getMinutes() : newDate.getMinutes()}`
		},
        sendComment(){
			this.isLoaded = true;
            this.$axios.post(`${this.url}api/comments/apprentice`,{
                content : this.text, 
				userMail: this.userMail,
                taskId: this.$route.params.idtask, 
				date: this.currentFullDate(),
				id: this.userID,
				apprenticeId: this.$route.params.id
				})
                .then((resp)=> {
                    const { success } = resp.data;
                    if(success){
						this.isLoaded = false;
                        this.text = '';
                    }
            })
        },
        getComments(){
                this.$axios.get(`${this.url}api/comments/apprentice/${this.$route.params.idtask}/${this.$route.params.id}`)
                .then((resp)=> {
                    this.comments = resp.data
            })
        },
        deleteComment(id){
            this.$axios.delete(`${this.url}api/comments/apprentice/${id}`)
                .catch((error) => {
                    throw error
                })
        }
    },
    created() {
        this.getComments();
        this.socket.on('commentAdded', () => {
            this.getComments()
        })
        this.socket.on('commentRemoved', () => {
            this.getComments()
        })
    },
}
</script>

<style lang="scss" scoped>
	.wordbreak {
		word-wrap: break-word;
		a{
			word-break: break-word;
		}
	}
</style>